import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Get in touch with us`}</h2>
    <p>{`Drop us an `}<a parentName="p" {...{
        "href": "mailto:info@kapani.de"
      }}>{`email at info (at) kapani.de`}</a>{` and we will get back to you as soon as possible. Our current response time is 3 working days.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      