import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About Us`}</h2>
    <h4>{`Your all-in-one solution for IT systems.`}</h4>
    <p>{`Kapani IT started in 2016 to be a hub to solve all IT related services. We believe in providing our customers with options and flexibility by enabling them to rely on our all-in-one mindset. From simple websites to complex AI-driven applications, it is important for us to work with our customers together in realising their projects. This approach also teaches us new things and provides opportunity to network and expand our client base. If you are interested in developing something with us, feel free to get in touch!`}</p>
    <p>{`Currently we are revamping the website, so check back later for more updates.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      