import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Our service areas`}</h2>
    <ProjectCard title="Web development" link="https://kapani.xyz" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
We design and develop website projects depending on your needs.
    </ProjectCard>
    <ProjectCard title="Mobile App development" link="https://kapani.xyz" bg="linear-gradient(to right, #FBB03B 0%, #D4145A 100%)" mdxType="ProjectCard">
Any app development on **iOS** and **Android** platforms.
    </ProjectCard>
    <ProjectCard title="AI" link="https://kapani.xyz" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
Integrate machine learning into your projects. We provide consultation and build AI solutions.
    </ProjectCard>
    <ProjectCard title="Digital Health" link="https://kapani.xyz" bg="linear-gradient(to right, #FBB03B 0%, #D4145A 100%)" mdxType="ProjectCard">
We specialise in developing digital healthcare solutions to optimise your workflow.
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      